<template>
  <v-app-bar id="Navbar" app fixed color="white">
    <v-app-bar-nav-icon class="ml-0" @click="brandClicked">
      <img :src="nav_brand" width="32px" />
    </v-app-bar-nav-icon>
    <v-toolbar-title>
      <b>{{ menu_name }}</b>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-avatar class="ml-2" color="#732151" size="32">
      <span class="white--text">A</span>
    </v-avatar>
  </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    menu_name: "NiXT ADMIN",
    nav_brand: require(process.env.VUE_APP_NAV_BRAND),
  }),
  methods: {
    brandClicked() {
      if (this.$route.name !== "home") {
        this.$router.push({ name: "home" });
      }
    },
  },
};
</script>
