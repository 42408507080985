<template>
  <v-card id="NavDrawer" class="mx-auto" flat>
    <v-list dense>
      <v-list-item-group
        color="primary"
        :value="current_route"
        @change="menuClicked"
      >
        <v-list-item v-for="(item, i) in items" :key="i" :value="item.route">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "NavDrawer",
  data: () => ({
    items: [
      { text: "Sites", icon: "mdi-city", route: "site" },
      { text: "Devices", icon: "mdi-memory", route: "device" },
      { text: "IoT Edges", icon: "mdi-merge", route: "edge" },
    ],
  }),
  computed: {
    current_route: function () {
      if (this.$route.name) {
        const route_name = this.$route.name;
        const root_route = route_name.includes("-")
          ? route_name.split("-")[0]
          : route_name;
        return root_route;
      }
      return null;
    },
  },
  methods: {
    menuClicked(page_name) {
      if (this.$route.name !== page_name) {
        this.$router.push({ name: page_name });
      }
    },
  },
};
</script>
