<template>
  <v-main id="NavbarLayout">
    <Navbar />
    <v-container fill-height fluid style="align-items: flex-start">
      <v-row style="height: inherit">
        <v-col cols="2" class="px-0" style="border-right: 1px solid #eeeeee">
          <NavDrawer />
        </v-col>
        <v-col>
          <router-view />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Navbar from "@/components/Navbar";
import NavDrawer from "@/components/NavDrawer";

export default {
  name: "NavbarLayout",
  components: {
    Navbar,
    NavDrawer,
  },
};
</script>
